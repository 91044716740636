(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

document.addEventListener('DOMContentLoaded', function (e) {
	var showTattleButton = document.getElementById('wpsn-show-tattle-form');
	var tattleContainer = document.getElementById('wpsn-tattle-container');
	var tattleSuccess = document.getElementById('wpsn-tattle-form-success');
	var tattleForm = document.getElementById('wpsn-tattle-form');

	if (!tattleForm) return;

	var msgErrorFileName = tattleForm.querySelector('[data-error="invalidFileName"]');
	var msgErrorMessage = tattleForm.querySelector('[data-error="invalidMessage"]');
	var submitButton = tattleForm.querySelector('[name="submit"]');
	var submitLoadingButton = document.getElementById('wpsn-tattle-form-submit-processing-icon');

	showTattleButton.addEventListener('click', function () {
		if (tattleContainer.classList.contains('show')) {
			tattleContainer.classList.remove('show');
			showTattleButton.classList.remove('is-showing');
			showTattleButton.value = "Report this file!";
		} else {
			tattleContainer.classList.add('show');
			showTattleButton.classList.add('is-showing');
			showTattleButton.value = "Oops nevermind...";
		}
	});

	tattleForm.addEventListener('submit', function (e) {
		e.preventDefault();

		// Reset the messages
		resetMessages();

		// Collect form data
		var data = {
			file_name: tattleForm.querySelector('[name="file_name"]').value,
			message: tattleForm.querySelector('[name="message"]').value
		};

		// Validate everything
		if (!data.file_name) {
			msgErrorFileName.classList.add('show');
			return;
		}

		if (!data.message) {
			msgErrorMessage.innerHTML = 'A message is required';
			msgErrorMessage.classList.add('show');
			return;
		}

		// AJAX POST HTTP request
		var url = tattleForm.dataset.url;
		var params = new URLSearchParams(new FormData(tattleForm));

		// Change visuals of buttons
		submitButton.value = "Sending...";
		submitButton.disabled = true;
		submitLoadingButton.classList.add("show");

		fetch(url, {
			method: "POST",
			body: params
		}).then(function (result) {
			return result.json();
		}).catch(function (error) {
			resetMessages();

			// Problem, restore visuals
			submitButton.value = 'Send Report';
			submitButton.disabled = false;
			submitLoadingButton.classList.remove("show");
			msgErrorMessage.innerHTML = 'Problem sending report, try again';
			msgErrorMessage.classList.add('show');
		}).then(function (response) {
			resetMessages();

			if (response === 0 || !response.status || response.status === 'error') {
				// Problem, restore visuals
				submitButton.value = 'Send Report';
				submitButton.disabled = false;
				submitLoadingButton.classList.remove("show");
				msgErrorMessage.innerHTML = 'Problem sending report, try again';
				msgErrorMessage.classList.add('show');
			} else {
				// Success, remove form from document and replace with success
				tattleForm.remove();
				tattleSuccess.classList.add('show');
			}
		});
	});

	function resetMessages() {
		msgErrorFileName.classList.remove('show');
		msgErrorMessage.classList.remove('show');
	}
});

},{}]},{},[1]);
